
<template>
  <!-- <div class="home"  id="home"> -->
  <template v-if="!isMobi">
    <el-carousel height="100vh" ref="carousel" :loop="false" direction="vertical" :autoplay="false" indicator-position="none">
      <el-carousel-item class="home-header">
        <div class="home-header-warp">
          <div class="home-header-top">
            <img class="home-header-top-right" src="http://qiniu.douyusm.cn/frond/app/img/a6.png" alt="" />
            <div>
              <img class="home-header-top-left" style="margin-right: 20px" src="@/assets/bnt2.png" @click="open('https://www.douyusm.cn')" alt="" /><img class="home-header-top-left" src="@/assets/us.png" @click="showMask = true" alt="" />
            </div>
          </div>
          <img class="home-title" src="http://qiniu.douyusm.cn/frond/app/img/a12.png" alt="" />
          <img class="home-title-two" src="http://qiniu.douyusm.cn/frond/app/img/a11.png" alt="" />
          <div class="home-header-bottom">
            <div class="download-ios" @mouseenter="showIos = true" @mouseleave="showIos = false">
              <img class="download-btn" src="@/assets/Group 3724 (2).png" alt="" />
              <img v-show="showIos" class="down-qr" src="@/assets/Group 4137.png" alt="" />
            </div>

             <div class="download-ios" @mouseenter="showAnd = true" @mouseleave="showAnd = false">
              <img class="download-btn" src="@/assets/Group 3726.png" alt="" />
              <img v-show="showAnd" class="down-qr" src="@/assets/Group 4138.png" alt="" />
            </div>
          
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item class="page-option">
        <img class="page-img" src="http://qiniu.douyusm.cn/frond/app/img/a2.png" alt="" />
      </el-carousel-item>
      <el-carousel-item class="page-option">
        <img class="page-img" src="http://qiniu.douyusm.cn/frond/app/img/a3.png" alt="" />
      </el-carousel-item>
      <el-carousel-item class="page-option">
        <div style="width: 100%; height: 100%; position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center">
          <img class="page-img" src="http://qiniu.douyusm.cn/frond/app/img/a4.png" alt="" />

          <div class="text-group">
            <p>北京汲瑞传媒有限公司</p>
            <p style="cursor: pointer"><span @click="showBusinessLicense = true">营业执照</span> <span @click="toBeian">京ICP备2021014303号-1</span></p>
            <p>Copyright 2023 hahamao Corporation,All Rights Reserved</p>
          </div>
        </div>
      </el-carousel-item>
      <div class="dot-group">
        <div
          v-for="item in 4"
          @click="currentChange(item)"
          :class="{
            'dot-item-current': item == current,
          }"
          :key="item"
          class="dot-item"
        ></div>
      </div>
      <!-- </div> -->
    </el-carousel>

    <div class="mask" v-show="showMask" @click="showMask = false">
      <img class="mask-img" src="http://qiniu.douyusm.cn/frond/app/img/a5.png" alt="" />
    </div>

    <div class="mask" v-show="showBusinessLicense" @click="showBusinessLicense = false">
      <img class="mask-img-two" src="http://qiniu.douyusm.cn/frond/app/img/a1.jpg" alt="" />
    </div>
  </template>
  <template v-else>
    <div class="h_home">
      <van-swipe class="my-swipe" touchable vertical :loop="false" :height="height" :duration="500" @change="swiperChange">
        <van-swipe-item>
          <div class="item_one item">
            <div class="item_one-warp">
              <img width="100%" height="auto" src="@/assets/Frame144.png" />
              <img class="text" src="@/assets/text.png" alt="" />
              <img class="text_two" src="@/assets/to p.png" alt="" />
              <div class="btn-box">
                <div class="btn" v-for="(item, index) in funBtnList" :key="index" @click="open(item.url)">
                  <img class="btn_image" :src="item.icon" alt="" />
                </div>
              </div>
              <div class="downLoadBtn-box">
                <div class="downLoadBtn" id="downloadButton" >
                  <img src="@/assets/Group 3724.png" alt="" />
                </div>
                <!-- @click="open('https://apps.apple.com/us/app/id6499410998', true)" -->
                <!-- @click="open('https://qiniu.douyusm.com/apk/douyusm.apk', true)" -->
                <div class="downLoadBtn" id="downloadButtonTwo" >
                  <img src="@/assets/Group 3727.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="item_other item">
            <img width="100%" height="auto" src="@/assets/Frame 145.png" />
            <img width="100%" class="image_center" src="@/assets/Group 3764.png" alt="" />
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="item_other item">
            <img width="100%" height="auto" src="@/assets/Frame 145.png" />
            <div width="100%" class="image_center">
              <img src="@/assets/Group 3731.png" style="margin: 0 auto; width: 90%; display: block" alt="" />
              <img src="@/assets/Group 3769.png" class="two_center" alt="" />
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <!-- <van-list style="height:100vh'"> -->
          <div class="scroll" ref="scrollableElement">
            <div class="item_other item">
              <img width="100%" height="auto" src="@/assets/Frame 145.png" />
              <img width="100%" class="image_center" src="@/assets/four.png" alt="" />
            </div>
            <img src="@/assets/Frame 143.png" width="100%" alt="" />
          </div>

          <!-- </van-list> -->
        </van-swipe-item>
        <!-- <van-swipe-item>
          
        </van-swipe-item> -->
        <template #indicator>
          <div class="custom-indicator">
            <div :class="['dot', hCurrent === item - 1 ? 'dot-current' : '']" v-for="item in 4" :key="item"></div>
          </div>
        </template>
      </van-swipe>

      <div class="more" v-if="hCurrent < 3">
        <img src="@/assets/Frame.png" class="more-icon" alt="" />
      </div>

      <van-dialog v-model:show="show" :show-confirm-button="false" width="220px" close-on-click-overlay @close="show = false">
        <img style="width: 220px" src="@/assets/Frame 141.png" />
      </van-dialog>

      <div class="wx-warp" @click="showWX = false" v-show="showWX">
        <img class="wx-img" src="@/assets/Frame 4509.png" mode="scaleToFill" />
        <div class="quan">
          <img class="q-text" src="@/assets/wxtext.png" mode="scaleToFill" />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref,  } from 'vue'

const isMobi = ref(/Mobi|Android/i.test(navigator.userAgent))

console.log(isMobi.value)

const current = ref(1)
const carousel = ref(null)
let height = ref(document.documentElement.clientHeight)
let div
const currentChange = (e) => {
  current.value = e
  if(carousel.value && carousel.value.setActiveItem) {
    carousel.value.setActiveItem(current.value - 1)
  }
}

const showIos = ref(false)
const showAnd = ref(false)
const showMask = ref(false)
const showBusinessLicense = ref(false)
const toBeian = () => {
  window.open('https://beian.miit.gov.cn', '_blank')
}
const openBusinessLicense = () => {
  window.open('https://file.inicoapp.com/admin_id_1623827568/image/618007c5579d000', '_blank')
}

var scrollableElement = ref(null)

function isWeixinBrowser(){
  const ua = navigator.userAgent.toLowerCase()
  return /micromessenger/.test(ua)
} 
function detectDevice() {
  const userAgent = navigator.userAgent;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else {
    return 'Unknown';
  }
}

if (!isMobi.value) {
  let time
  let isStart
  window.addEventListener('wheel', (e) => {
    // console.log(time)
    if (!isStart) {
      isStart = true
      if (e.wheelDelta < 0) {
        if (current.value < 4) {
          current.value++
          if( carousel.value) {
             carousel.value.setActiveItem(current.value - 1)
          }
        }
      } else {
        if (current.value > 1) {
          current.value--
          if(carousel.value) {
             carousel.value.setActiveItem(current.value - 1)
          }
        }
      }
      setTimeout(() => {
        isStart = false
      }, 500)
    }
  })
   

} else {
 
  onMounted(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://res.openinstall.com/openinstall-zlp810.js";
    s.addEventListener(
      "load",
      () => {
        var data = OpenInstall.parseUrlParams();
        console.log(data);
        new OpenInstall(
          {
            appKey: 'qxrj9x', //appkey参数配置,需要自行替换对应的appkey
            onready: function () {
              var m = this,
                button = detectDevice()==='iOS' ? document.getElementById("downloadButton") : document.getElementById("downloadButtonTwo");

              // m.schemeWakeup();
              // if (!isWeixinBrowser()) {
              //   m.wakeupOrInstall();
              // }
              button.onclick = function () {
                if (isWeixinBrowser()) return (showWX.value = true);
                m.wakeupOrInstall();
                return false;
              };
            },
          },
          data
        );
      },
      false
    );
    document.head.appendChild(s);
  })
  
}

const hCurrent = ref(0)
const show = ref(false)
const funBtnList = ref([
  {
    icon: require('@/assets/btn1.png'),
    url: 'https://bd.douyusm.com',
  },
  {
    icon: require('@/assets/bnt2.png'),
    url: 'https://www.douyusm.cn',
  },
  {
    icon: require('@/assets/btn3.png'),
    url: '',
  },
])

const swiperChange = (e) => {
  // console.log(e)
  hCurrent.value = e
  if (hCurrent.value !== 3) {
    scrollableElement.value.scrollTop = 0
  }
}
const showWX = ref(false)

const open = (url, wx) => {
  if (wx) {
    const ua = navigator.userAgent.toLowerCase()
    if (/micromessenger/.test(ua)) {
      showWX.value = true
      return
    }
  }
  if (url) {
    window.location.href = url
  } else {
    console.log('没有url')
    show.value = true
  }
}
</script>

<style scoped lang="scss">
.home {
  min-width: 920px;
  // height: 100vh;
  overflow: scroll; /* overflow 为 scroll，来把滚动条设置到 main 元素上 */
  // scroll-snap-type: y mandatory;
  // scroll-padding: 0px; /* 空车顶部菜单的高度 */
  // scroll-behavior: smooth; /* 添加平滑滚动效果 */
}
::-webkit-scrollbar {
  display: none;
  /* 修改宽度 */
}
.home-header {
  height: 100%;
  width: 100%;
  background-image: url('http://qiniu.douyusm.cn/frond/app/img/a9.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-snap-align: center;

  .home-header-warp {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: rgba(#000000, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .home-header-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 31px 18.75%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .home-header-top-right {
        width: 220px;
        cursor: pointer;
      }
      .home-header-top-left {
        width: 94px;
        cursor: pointer;
      }
    }
    .home-title {
      width: 986px;
      margin-top: 180px;
    }
    .home-title-two {
      width: 456px;
      margin-top: 80px;
    }

    .home-header-bottom {
      display: flex;
      .download-btn {
        width: 272px;
        &:first-of-type {
          margin-right: 64px;
          margin-top: 120px;
        }
      }
      .download-ios {
        position: relative;
        .down-qr {
          position: absolute;
          width: 184px;
          bottom: 0;
          left: 50%;
          transform: translateY(95%) translateX(-70%);
        }
      }
    }
  }
}
.page-option {
  height: 100%;
  width: 100%;
  scroll-snap-align: center;
  background-image: url('http://qiniu.douyusm.cn/frond/app/img/a10.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  line-height: 24px;
  // position: relative;
  .text-group {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .page-img {
    width: 100%;
  }
}
.page-option-bottom {
  height: 200px;
  flex-direction: column;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  line-height: 24px;
}
.dot-group {
  position: fixed;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  .dot-item {
    width: 8px;
    height: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: rgba(198, 198, 198, 1);
    transition: height 0.2s linear;
  }
  .dot-item-current {
    height: 20px;
    background: linear-gradient(180deg, #af6aef 0%, #885bf3 100%);
  }
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .mask-img {
    width: 420px;
  }
  .mask-img-two {
    width: 800px;
  }
}
img {
  user-select: none;
}

::v-deep .van-dialog {
  background-color: transparent;
}

.my-swipe {
  height: 100vh;
  .item {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .item_one {
    background-image: url('../assets/image-281.png');
    background-size: cover;
    background-position: center;
    &-warp {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-color: rgba(0, 0, 0, 0.4);
      .text {
        width: 80%;
        margin-top: 68px;
      }
      .text_two {
        width: 38%;
        margin-top: 12px;
      }
      .btn-box {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .btn {
          width: 30%;
        }
        .btn_image {
          width: 100%;
        }
      }
      .downLoadBtn-box {
        position: absolute;
        bottom: 115px;
        left: 50%;
        transform: translateX(-50%);
      }
      .downLoadBtn {
        width: 148px;
        img {
          width: 100%;
        }
      }
      .downLoadBtn + .downLoadBtn {
        margin-top: 18px;
      }
    }
  }
  .item_other {
    background-image: url('../assets/bg.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;
    .image_center {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      .two_center {
        width: 72.5%;
        margin-top: 11px;
        margin-left: 21.5%;
      }
    }
  }
}
.more {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
  img {
    width: 32px;
  }
}
.item_bottom {
  position: absolute;
  height: 100%;
  overflow-y: scroll;
}
.custom-indicator {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: rgba(198, 198, 198, 1);
    margin-bottom: 8px;
    transition: height 0.5s;
  }
  .dot-current {
    height: 22px;
    transition: height 0.5s;
    background: linear-gradient(180deg, #af6aef 0%, #885bf3 100%);
  }
}
.scroll {
  height: 100vh;
  overflow-y: auto;
}
.wx-warp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  .wx-img {
    position: absolute;
    right: 40px;
    width: 98px;
    height: 98px;
    top: 7px;
  }
  .quan {
    width: 199px;
    height: 91px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('@/assets/Rectangle 3199.png');
    background-size: 100% 100%;
    position: absolute;
    right: 125px;
    top: 98px;
  }
  .q-text {
    width: 159px;
  }
}
</style>
